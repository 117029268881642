// 组件
import importImg from '@/components/importImg.vue'
import alterForm from '@/components/alterForm'
// 接口
import { getShopList, getShopAdminUsersList, getStoreEditDetails, getGlobalLabelList, putShopsFormData, getDeliveryZonesSimpleList } from '@/services/store.js'
// 其他
import loading from '@/utils/loading.json'
import jsonData from './jsonData'
import constant from '@/utils/constant.js'

export default {
  components: {
    importImg,
    alterForm
  },
  data() {
    return {
      modifyData: {},
      collapseLength: [],
      formData: jsonData.formData
    }
  },
  watch: {
  },
  created() {
    this.init()
  },
  mounted() {
  },
  methods: {
    init() {
      this.collapseLength = [...new Array(this.formData.length).keys()]
      this.$store.state.app.loading = this.$loading(loading)
      getShopAdminUsersList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[7].options = res.data.admin_users
        }
      })
      getShopList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[10].options = res.data.collections.item_types
          this.formData[1].createDateItem[1].options = res.data.collections.display_types
          this.formData[1].createDateItem[2].options = res.data.collections.shop_category_item_sorting_methods
          this.formData[1].createDateItem[3].options = res.data.collections.tag_choices
          this.formData[2].createDateItem[3].options = res.data.collections.pick_up_display_mode_options
          this.formData[3].createDateItem[3].options = res.data.collections.pick_up_display_mode_options
          this.formData[4].createDateItem[3].options = res.data.collections.pick_up_display_mode_options
          this.formData[2].createDateItem[10].options = res.data.collections.express_fee_mode_options
          this.formData[3].createDateItem[10].options = res.data.collections.express_fee_mode_options
          this.formData[6].createDateItem[0].options = res.data.collections.paynow_type_options
          this.formData[6].createDateItem[4].options = res.data.collections.stripe_type_options
          this.formData[2].createDateItem[7].createDateFirstItem[5].options = res.data.collections.weekdays
          this.formData[3].createDateItem[7].createDateFirstItem[6].options = res.data.collections.weekdays
        }
      })
      getGlobalLabelList().then(res => {
        if (res.status === 200) {
          this.formData[0].createDateItem[21].options = res.data.global_labels
        }
      })
      if (['combine'].includes(this.app_name)) {
        getDeliveryZonesSimpleList().then(res => {
          this.formData[3].createDateItem[7].createDateFirstItem[0].options = res.data.delivery_zones
        })
      }

      getStoreEditDetails(this.$route.params.id).then(res => {
        if (res.status === 200) {
          const data = res.data.shop
          //const data = Object.assign({}, res.data.shop)
          if(!['combine'].includes(this.app_name)) {
            data.host_delivery_time_slots_attributes = data.delivery_time_slots_attributes
            delete data.delivery_time_slots_attributes
          }
          this.modifyData = data
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    formSubmit(data) {
      this.$store.state.app.loading = this.$loading(loading)
      const obj = JSON.parse(JSON.stringify(data))
      if (!['combine'].includes(this.app_name)) {
        obj.delivery_time_slots_attributes = obj.host_delivery_time_slots_attributes
        delete obj.host_delivery_time_slots_attributes
      }
      const params = {
        shop: obj
      }
      params.shop.remote_logo_url = params.shop.logo
      params.shop.remote_paynow_qrcode_url = params.shop.paynow_qrcode
      delete params.shop.logo
      delete params.shop.paynow_qrcode
      delete params.shop.weekdays
      Object.keys(params.shop).forEach(res => {
        if (typeof params.shop[res] === 'boolean') {
          if (params.shop[res] === true) params.shop[res] = '1'
          if (params.shop[res] === false) params.shop[res] = '0'
        }
      })
      console.log(params, 'params')
      putShopsFormData(params, this.$route.params.id).then(res => {
        if (res.status === 200) {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
          setTimeout(() => {
            this.$router.push({
              name: 'shopsManagerList'
            })
          }, constant.ROUTER_CHANGE_INTERVAL)
        }
      }).finally(() => {
        this.$store.state.app.loading.close()
      })
    },
    removeArray (createData, obj) {
      if (createData[obj.prop][obj.index].id) {
        this.$set(createData[obj.prop][obj.index], obj.mark, true)
        // createData[obj.prop][obj.index][obj.mark] = createData[obj.prop][obj.index].id
      } else {
        createData[obj.prop].splice(obj.index, 1)
      }
    }
  }
}
